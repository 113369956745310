/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = (props) => {
  const { accentColor, lang, meta, title } = props;

  const location = typeof window !== 'undefined' && window.location;

  const { favicon, appleTouchIcon, wp, site } = useStaticQuery(
    graphql`{
  site {
    siteMetadata {
      siteURL
    }
  }
  wp {
    seo {
      schema {
        siteName
      }
      openGraph {
        defaultImage {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, height: 630, placeholder: BLURRED, layout: FIXED)
            }
          }
        }
        frontPage {
          description
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1200, height: 630, placeholder: BLURRED, layout: FIXED)
              }
            }
          }
        }
      }
      social {
        twitter {
          cardType
          username
        }
      }
    }
  }
  favicon: file(relativePath: {eq: "favicon.png"}) {
    publicURL
  }
  appleTouchIcon: file(relativePath: {eq: "touch-icon.png"}) {
    publicURL
  }
}
`
  );

  let { siteURL } = site.siteMetadata;
  siteURL = siteURL.replace('/inabox', '');

  const { openGraph, social, schema } = wp.seo;
  const { frontPage = {}, defaultImage } = openGraph;
  const pageTitle = title || meta.title || frontPage.title || schema.siteName;
  const description = meta.metaDesc || frontPage.description;
  const mergedMeta = Object.entries(meta).length ? meta : {};
  const defaultSocialImage =
    frontPage && frontPage.image && frontPage.image.localFile.childImageSharp
      ? frontPage.image.localFile.childImageSharp.gatsbyImageData.srcWebp
      : defaultImage && defaultImage.localFile.childImageSharp
      ? defaultImage.localFile.childImageSharp.gatsbyImageData.srcWebp
      : '';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      style={[
        {
          cssText: `
          :root {
            --accent-color: var(--color-${accentColor})
          }
        `,
        },
      ]}
      link={[
        {
          rel: 'canonical',
          href: siteURL + location.pathname || meta.canonical,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: favicon.publicURL,
        },
        {
          rel: 'apple-touch-icon',
          type: 'image/png',
          sizes: '120x120',
          href: appleTouchIcon.publicURL,
        },
      ]}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:locale',
          content: 'en_US',
        },
        {
          property: 'og:title',
          content: mergedMeta.opengraphTitle || pageTitle,
        },
        {
          property: 'og:description',
          content: mergedMeta.opengraphDescription || description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: siteURL + location.pathname,
        },
        {
          name: 'twitter:card',
          content: social.twitter.cardType,
        },
        {
          name: 'twitter:site',
          content: `@${social.twitter.username}`,
        },
        {
          name: 'twitter:creator',
          content: `@${social.twitter.username}`,
        },
        {
          name: 'twitter:title',
          content: mergedMeta.twitterTitle || pageTitle,
        },
        {
          name: 'twitter:description',
          content: mergedMeta.twitterDescription || description,
        },
        {
          property: 'og:image',
          content: `${siteURL}${
            mergedMeta.opengraphImage
              ? mergedMeta.opengraphImage.localFile.childImageSharp.gatsbyImageData
                  .srcWebp
              : defaultSocialImage
          }`,
        },
        {
          property: 'og:image:secure_url',
          content: `${siteURL}${
            mergedMeta.opengraphImage
              ? mergedMeta.opengraphImage.localFile.childImageSharp.gatsbyImageData
                  .srcWebp
              : defaultSocialImage
          }`,
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          name: 'twitter:image',
          content: `${siteURL}${
            mergedMeta.twitterImage
              ? mergedMeta.twitterImage.localFile.childImageSharp.gatsbyImageData.srcWebp
              : defaultSocialImage
          }`,
        },
      ]}
    />
  );
};

Seo.defaultProps = {
  accentColor: 'coral',
  lang: 'en',
  meta: {},
  title: '',
};

Seo.propTypes = {
  accentColor: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  title: PropTypes.string,
};

export default Seo;
